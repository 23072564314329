import * as React from 'react'
import styled, { useTheme } from 'styled-components'

// import { useI18n } from '@bestyled/primitives'
// import { Headings, Section, mediaqueries } from '@slipway/theme-components'
import SEO from '../components/SEO'
import Layout from '../components/Layout'
import HeroLanding from '../sections/Landing/Landing.Hero'
import { LandingBenefits } from '../sections/Landing/Landing.Benefits'
import { LandingTestimonials } from '../sections/Landing/Landing.Testimonials'
import {LandingFAQ} from '../sections/Landing/Landing.FAQ'
import HiddenAnchor from '../components/TComponents/HiddenAnchor'
import { useAppContext, state } from '../utils/global-context'




const LandingPage = ({ location, pageContext }) => {
  const theme = useTheme()
  console.debug('context', pageContext)
  console.debug('theme', theme)

  const Benefits = () => <LandingBenefits { ...pageContext } benefits={ pageContext.translations.benefits } theme={ theme } />
  const Testimonials = () => <LandingTestimonials { ...pageContext } testimonials={ pageContext.translations.testimonials } theme={ theme } />
  return (
    <Layout
      {...pageContext}
      location={location}
      basePath={pageContext.basePath}
      locale={pageContext.locale}
      languages={pageContext.languages}
      translations={pageContext.translations}
    >
      <SEO pathname={location.pathname} />
      <HeroLanding context={ pageContext }></HeroLanding>
      <div id="landing-content">
        <Benefits />
        {/* @ts-ignore */}
       {theme.hideComponents !== true && <LandingFAQ {...pageContext} theme={theme} components={{Benefits, Testimonials}} />}
        <Testimonials />
      </div>
    </Layout>
  )
}
export default LandingPage


