/* eslint-disable no-restricted-globals */
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import styled from 'styled-components'

import { useI18n } from '@bestyled/primitives'
import qs from 'query-string'
import _ from 'lodash'
import { FiPlay } from 'react-icons/fi'

import mediaqueries from '../styles/media'

import Typeform from './TComponents/Typeform'
import Modal from './Modal'
const siteQuery = graphql`
    {
      allPage(filter: { menu: { glob: "action*" } }, sort: { fields: menu, order: ASC }) {
        edges {
          node {
            id
            menu
            menuTitle
            lang
            slug
            link
            title
          }
        }
      }
    }
  `

export const NavigationMenuAction: React.FC<{ siteData: any }> = ({ siteData }) => {
  let result = siteQuery
  try {
    result = useStaticQuery(siteQuery)
  } catch (error) {
    result = siteQuery
  }
  // @ts-ignore
  const { allPage: menuItems } = result
  const { isCompatible, locale, t } = useI18n()
  const [modalVisibility, setmodalVisibility] = React.useState(false)
  const [contentLink, setContentLink] = React.useState()

  const setModalVisibilityL = (link) => {
    setContentLink(link)
    setmodalVisibility(true)
  }
  let registrationButtonLink
  if (siteData && siteData.registrationLink) {
    let { link, ...query } = siteData.registrationLink

    registrationButtonLink = qs.stringifyUrl({
      url: link,
      query
    })
  }
  const navItems = menuItems.edges
    .map((edge) => ({
      id: edge.node.id,
      title: edge.node.menuTitle || edge.node.title,
      href: edge.node.link || edge.node.slug,
      lang: edge.node.lang
    }))
    .filter((node) => isCompatible(node.lang))

  return (
    <ActionBar>
      <ActionNavList id='action-list'>
        <Typeform url={registrationButtonLink} {...(siteData ? siteData.registrationLink : {})}>
          {t('site.landingRegistrationButtonText')}
        </Typeform>
        <ActionItems items={navItems} toggleModal={(link) => setModalVisibilityL(link)} />
      </ActionNavList>
      <Modal show={modalVisibility} link={contentLink} setShow={(show) => setmodalVisibility(show)}></Modal>
    </ActionBar>
  )
}

export default NavigationMenuAction

var ActionBar = styled.div``

const ActionNavList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  background: inherit;
  list-style: none;
  justify-content: left;
`
const ActionButtonList = styled.ul`
  display: flex;
  align-items: left;
  background: inherit;
  list-style: none;
  justify-content: left;
  flex-direction: row;

  /* ${mediaqueries.phablet`
  `}; */
`

const NavItem = styled.li`
  padding-right: 20px;

  ${mediaqueries.tablet`
    min-width: 150px;
    padding-right: 0;
    display: flex;
    flex-flow: row;
    justify-content: center;
  `};
`

const ActionButton = styled.button`
  font-family: ${(p) => p.theme.fonts.menu};
  font-weight: ${(p) => p.theme.fonts.weights.heavy};
  font-size: ${(p) => p.theme.fontSizes[0]}px;
  color: ${(p) => p.theme.colors.grey};
  -webkit-appearance: button;
  display: inline-block;
  box-sizing: border-box;
  margin: 0;
  overflow: visible;
  text-align: left;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  line-height: 2.2;
  /* margin-top: 1.5rem !important; */
  letter-spacing: 1.7px;
  text-transform: uppercase;
  border-radius: 2px;
  outline: none;
  transition: 0.15s linear;
  cursor: pointer;
  padding: 0;
  * {
    letter-spacing: 1.7px;
  }

  a {
    display: flex;
    flex-flow: row wrap;
    ${mediaqueries.desktop_large`
      padding-left: 5px;
    `}

    * {
      align-self: center;
    }
  }

  svg {
    font-size: ${(p) => p.theme.fontSizes[1]}px;
  }
`

const ActionItems: React.FC<{
  items: { id: string; title: string; href: string; disabled?: boolean }[]
  toggleModal: any
}> = ({ items, toggleModal }) => {
  const { t } = useI18n()

  return (
    <ActionButtonList>
      {[
        ...items.map((nav, index) => {
          return (
            <NavItem key={nav.id}>
              <ActionButton onClick={() => toggleModal(nav.href)}>
                <a>
                  <FiPlay /> {'\u00A0'}
                  {nav.title}
                </a>
              </ActionButton>
            </NavItem>
          )
        })
      ]}
    </ActionButtonList>
  )
}
